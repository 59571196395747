body, html {
  background-color: #172c35;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  display: flex;
}

.centered-container {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

img {
  width: 50wv;
  max-width: 50vw;
  max-height: 25vw;
  margin: 10px 0;
}

.icon-dkp {
  max-width: 10vw;
  margin: 10px 0;
}

.icp-logo {
  width: 16vw;
}

.text-d {
  text-align: center;
  font-size: 1.1vw;
}

.btn {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #55b941;
  border-radius: 10px;
  margin: 40px 2px;
  padding: 10px 25px;
  font-size: 19px;
  font-weight: 900;
  text-decoration: none;
  display: inline-block;
}

p {
  color: #fff;
  margin: 1% 0;
}

.hidden {
  display: none;
}

.alert {
  color: #fff;
  background-color: #f44336;
  margin-bottom: 15px;
  padding: 20px;
}
/*# sourceMappingURL=index.7166bcba.css.map */
