body, html {
    height: 100%; /* Make sure the html and body are full height */
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, sans-serif;
    background-color: #172C35;
}

.centered-container {
    text-align: center; /* Center text elements horizontally */
    width: 100%; /* Use full width */
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children vertically */
    align-items: center; /* Center children horizontally */
}

img {
    max-width: 50vw;
    width: 50wv;
    max-height: 25vw;
    margin: 10px 0; /* Add some vertical margin around images */
}

.icon-dkp {
    max-width: 10vw;
    margin: 10px 0; /* Add some vertical margin around images */

}

.icp-logo {
    width: 16vw;
}

.text-d {
    font-size: 1.1vw;
    text-align: center;
}
.btn {
    background-color: #55B941;
    border-radius: 10px ;
    color: white;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 19px;
    font-weight: 900;
    margin: 40px 2px;
    cursor: pointer;
}

p {
    color: white;
    margin: 1% 0; /* Manage spacing around headings */
}

.hidden {
    display: none;
}


.alert {
    padding: 20px;
    background-color: #f44336; /* Red */
    color: white;
    margin-bottom: 15px;
  }
  